.all_profiles {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.profile_card {
    position: relative;
    width: 200px;
    min-height: 200px;
    max-height: 300px;
    border-radius: 8px;
    flex: 1 1 200px;
    overflow: hidden;
    transition: border 0.3s ease-in-out;
}

.profile_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s, box-shadow 0.3s;
}

.profile_card:hover img {
    transform: scale(1.01);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.profile_card.active_profile {
    border: 4px solid #00f; 
    box-shadow: 0 0 15px rgba(0, 0, 255, 0.5); 
}

.check_mark {
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 24px;
    color: rgb(31, 32, 31);
    font-weight: bold;
}

.delete_button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
}

.profile_card:hover .delete_button {
    opacity: 1;
}

.delete_button:hover {
    background-color: rgba(255, 0, 0, 1);
}
