@import "../../styles/Scss/main";

.overview-personal-info {
  margin-top: 24px;
  margin-bottom: 40px;
  margin-right: 10px;
}

.profile-img {
  max-width: 300px;
  width: 100%;
  padding: 30px !important;
}

.user-data {
  margin-bottom: 5px;

  i {
    font-size: 20px;
    margin-right: 6px;
  }
}

.trainerStat { 
  height: 100%;
  padding: 20px; 

  .trainerStatFirstDiv{
    height: 50%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    
    .sessionStyle {
      width: 33%;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
      p {text-align:center;}
    }
    .retentionStyle {
      flex-direction:column;
      justify-content: center;
      align-items: center; 
      position: relative;
      p {text-align:center; margin-top: 10px;}}
    .progressStyle {
      width:34%; 
      height:50px; 
      margin-bottom:50px;
      .CircularProgressbar {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
      } 
    }
  }
}

.signin-type{
padding: 8px;
width: 75%;
color: white;
font-weight: bold;
text-align: center;
}
.google-signin-color{
  background: rgba(255, 0, 0, 0.8);
}
.facebook-signin-color{
  background: #3578e5;
}
.apple-signin-color{
  background: black;
}
.credential-signin-color{
  background: #32c5d2;
}


div {
  position: relative;

  .icon-cross,
  .icon-upload {
    position: absolute;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  .seperate-upload-icon{
   
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    &:hover{
      color: greenyellow;
    }
  }

  .icon-cross {
    top: 10px;
    right: 10px;
    opacity: 0;
    transform: scale(0);
    &:hover{
      color: rgb(236, 71, 71);
    }
  }

  .icon-upload {
    top: 10px;
    left: 10px;
    opacity: 0;
    transform: scale(0);
    &:hover{
      color: greenyellow;
    }
  }

  &:hover {
    .icon-cross,
    .icon-upload {
      opacity: 1;
      transform: scale(1);
    }
  }
}
